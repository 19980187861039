<template>
  <v-card flat>
    <v-divider />
    <div v-for="item in items" :key="item.student.id">
      <v-row dense align="center">
        <v-col>
          <PersonItem :value="item.student" flags />
        </v-col>
        <v-col>
          <v-btn
            :loading="periodItem.loading"
            @click="!disabled(periodItem) ? toggle(item, periodItem) : ''"
            class="mr-3 my-1"
            :class="{ disabled: disabled(periodItem) }"
            large
            dark
            depressed
            :ripple="!disabled(periodItem)"
            :outlined="disabled(periodItem)"
            :color="color(periodItem)"
            v-for="periodItem in item.periodItems"
            :key="item.student.id + 'period' + periodItem.period.id"
            >{{ periodItem.period.startTime }} bis
            {{ periodItem.period.endTime }}</v-btn
          >
          <v-tooltip top v-if="item.absentElsewhere">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" :attrs="attrs" left>mdi-school</v-icon>
            </template>
            <span>In einem Parallelkurs als abwesend registriert.</span>
          </v-tooltip>
          <v-tooltip top v-if="item.presentElsewhere">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" :attrs="attrs" left>mdi-calendar</v-icon>
            </template>
            <span>Wegen eines Schulanlasses abwesend.</span>
          </v-tooltip>
          <v-tooltip
            top
            v-for="justification in item.justifications"
            :key="'justification' + justification.id"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" :attrs="attrs" left>{{
                justificationCategoryIcon(justification.category)
              }}</v-icon>
            </template>
            Absenz: {{ justification.category.description }}
          </v-tooltip>

          <v-tooltip
            top
            v-for="dispensation in item.dispensations"
            :key="'dispensation' + dispensation.id"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" :attrs="attrs" left>mdi-cancel</v-icon>
            </template>
            <span
              >{{
                dispensation.physicalEducation
                  ? "Sportdispensation"
                  : "Dispensation"
              }}<span v-if="dispensation.description">: </span>
              {{ dispensation.description }}</span
            >
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="text-right mr-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on"> {{ item.absences }}</v-chip>
            </template>
            <span>Anzahl Absenzen in diesem Kurs in diesem Semester</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider />
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";
import { justificationCategoryIcon } from "common/utils/icons.js";

export default defineComponent({
  components: { PersonItem },
  props: ["items"],
  data() {
    return {};
  },
  methods: {
    justificationCategoryIcon,
    color(periodItem) {
      if (periodItem.dispensated || periodItem.presentElsewhere) {
        return "info";
      }
      if (periodItem.absentElsewhere) {
        return "danger";
      }
      if (periodItem.absent) {
        return "danger";
      } else {
        return "success";
      }
    },
    disabled(periodItem) {
      return (
        periodItem.loading ||
        periodItem.dispensated ||
        periodItem.absentElsewhere ||
        periodItem.presentElsewhere
      );
    },
    async toggle(item, periodItem) {
      this.$set(periodItem, "loading", true);
      this.$emit("toggle", item, [periodItem], !periodItem.absent); // periodItem.absent verwenden
    },
  },
});
</script>

<style scoped>
.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.v-btn {
  border: thin solid currentColor;
}
</style>
